import * as React from "react";
import { View, Platform } from 'react-native';
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import SpeakersPage from "../pages/Speakers/SpeakerPage";
import SpeakerInnerPage from "../pages/Speakers/SpeakerInnerPage";
import BackButton from "../components/BackButton";
import HeaderRight from "../components/HeaderRight";
import DonateButton from "../components/DonateButton";

const Stack = createNativeStackNavigator();

const SpeakersStackbNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SpeakersPage"
        options={{ headerShown: false, title: "Speakers - The Podcast Fellowship" }}
        component={SpeakersPage}
      />
      <Stack.Screen
        name="SpeakerInnerPage"
        options={{
          headerStyle: {},
          title: "",
          headerShadowVisible: false,
          headerBackTitleVisible: false,
          headerRight:() => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 27, marginHorizontal: Platform.OS === 'web' ? 20 : 0, marginTop: Platform.OS === 'web' ? -4 : 0}}><DonateButton /><HeaderRight /></View>,
          headerLeft: (props) => (<View style={{ marginHorizontal: Platform.OS === 'web' ? 20 : 0}}>
            <BackButton
              navigation={navigation}
              skipCheck
              {...props}
              backLinkTo={"/podcasts/speakers"}
            /></View>
          ),
        }}
        component={SpeakerInnerPage}
      />
    </Stack.Navigator>
  );
};

const SpeakersTabNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Speakers"
        component={SpeakersStackbNavigator}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default SpeakersStackbNavigator;
