import { createDrawerNavigator } from "@react-navigation/drawer";
import CustomDrawerContent from "../components/CustomDrawerContent";
import TabNavigator from "./TabNavigator";
import {
  ContactUsPageStack,
  AboutUsPageStack,
  DonatePageStack,
  PrivacyPolicyPageStack,
  HomePageStack,
  TriviaPageStack
} from "./WebSitePagesNavigation";
import { useIsAuthenticated } from "@podcasts/hooks";
import Icon from "../components/svg/icon";
import { Platform } from "react-native";
import { NativeApplicationProcess, WebApplicationProcess  } from "../pages/ApplicationProcess/ApplicationProcess";
import { MentorApplication, MentorWebApplication  } from "../pages/MentorApplication/MentorApplication";

const PodcastChildren = [
  {
    key: "Featured",
    nav: "Podcasts",
    icon: <Icon name="Featured" height="20" width="20" fill="none" />,
    routeName: "HomePage",
    title: "Featured",
    path: "/podcasts/featured",
  },
  {
    key: "Courses",
    nav: "Podcasts",
    icon: <Icon name="Series" height="20" width="20" fill="#fff" />,
    routeName: "CoursesPage",
    title: "Courses",
    path: "/podcasts/courses",
  },
  {
    key: "Topics",
    nav: "Podcasts",
    icon: <Icon name="Topics" height="20" width="20" fill="#fff" />,
    routeName: "TopicsPage",
    title: "Topics",
    path: "/podcasts/topics",
  },
  {
    key: "Speakers",
    nav: "Podcasts",
    icon: <Icon name="Speakers" height="20" width="20" fill="#fff" />,
    routeName: "SpeakersPage",
    title: "Speakers",
    path: "/podcasts/speakers",
  },
  {
    key: "Search",
    nav: "Podcasts",
    icon: <Icon name="Search" height="20" width="20" fill="#fff" />,
    routeName: "SearchPage",
    title: "Search",
    path: "/podcasts/search",
  },
];
const Drawer = createDrawerNavigator();
const MainDrawerNavigation = () => {
  const isAuthenticated = useIsAuthenticated();

  const drawerItemsMain = [
    ...(isAuthenticated
      ? [
          {
            key: "Settings",
            title: "Podcast Library",
            icon: <Icon name="Featured" height="20" width="20" fill="none" />,
            hasChild: true,
            routes: PodcastChildren,
          },
        ]
      : PodcastChildren),
    ...(isAuthenticated
      ? [
          {
            key: "Profile",
            title: "Your Profile",
            icon: <Icon name="Profile" height="20" width="20" fill="#fff" />,
            path: "/profile",
          },
        ]
      : []),
  ];

  const draweBottomItems = [
    {
      key: "About",
      title: "About",
      path: "/aboutus",
    },
    {
      key: "Contact",
      title: "Contact",
      path: "/contact",
    },
    {
      key: "Donate",
      title: "Donate",
      path: "/donate",
      styles: {color: "#FCB912", textDecorationLine: "underline",},
    },
    {
      key: "Trivia",
      title: "Trivia",
      path: "/trivia",
    },
    {
      key: "Mentor Apply",
      title: "Mentor Apply",
      path: "/mentor-apply",
    },
    {
      key: "Privacy",
      title: "Privacy Policy",
      path: "/privacy-policy",
    },
    {
      key: "Recent",
      library: true,
      title: "Recent",
      path: "/podcasts/library/recent",
      icon: <Icon name="Clock" height="20" width="20" fill="#fff" />,
    },

    {
      key: "SavedPodcasts",
      library: true,
      title: "Saved Podcasts",
      path: "/podcasts/library/podcasts",
      icon: <Icon name="Save" height="20" width="20" fill="#fff" />,
    },
    {
      key: "SavedTopics",
      library: true,
      title: "Saved Topics",
      path: "/podcasts/library/topics",
      icon: <Icon name="Folder" height="20" width="20" fill="#fff" />,
    },
    {
      key: "SavedSpeakers",
      library: true,
      title: "Saved Speakers",
      path: "/podcasts/library/speakers",
      icon: <Icon name="Profile" height="20" width="20" fill="#fff" />,
    },
  ];

    draweBottomItems.unshift({
      key: "Home",
      title: "Home",
      path: "/",
    });
  

  return (
    <Drawer.Navigator
      useLegacyImplementation={false}
      screenOptions={{
        drawerType: "slide",
        drawerStyle: {
          backgroundColor: "#183c66",
          width: '100%',
        },
        header: ({ navigation, route, options }) => null,
      }}
      drawerContent={(props) => (
        <CustomDrawerContent
          drawerItems={drawerItemsMain}
          {...props}
          draweBottomItems={draweBottomItems}
        />
      )}
    >
      <Drawer.Screen
        name="Podcasts"
        options={{ drawerLabel: "Podcasts" }}
        component={TabNavigator}
      />

      <Drawer.Screen
        name="ContactUsPageStack"
        options={{ drawerLabel: "ContactUsPageStack" }}
        component={ContactUsPageStack}
      />


      <Drawer.Screen
        name="PrivacyPolicyPageStack"
        options={{ drawerLabel: "PrivacyPolicyPageStack" }}
        component={PrivacyPolicyPageStack}
      />

      <Drawer.Screen
        name="AboutUsPageStack"
        options={{ drawerLabel: "" }}
        component={AboutUsPageStack}
      />
      <Drawer.Screen
        name="DonatePageStack"
        options={{ drawerLabel: "" }}
        component={DonatePageStack}
      />
      <Drawer.Screen
        name="TriviaPageStack"
        options={{ drawerLabel: "" }}
        component={TriviaPageStack}
      />

      <Drawer.Screen
        name="Application"
        options={{ drawerLabel: "" }}
        component={Platform.OS === 'web' ? WebApplicationProcess : NativeApplicationProcess}
      />

      <Drawer.Screen
        name="MentorApplication"
        options={{ drawerLabel: "" }}
        component={Platform.OS === 'web' ? MentorWebApplication : MentorApplication}
      />

      <Drawer.Screen
        name="HomePageStack"
        options={{ drawerLabel: "HomePageStack" }}
        component={HomePageStack}
      />

    </Drawer.Navigator>
  );
};

export default MainDrawerNavigation;
