import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  SafeAreaView,
  Pressable,
} from "react-native";
import Icon2 from "../components/icons/CustomIcon2";
import Title from '../components/Title';

export default function AboutUsPage() {
  return (
    <SafeAreaView>
    <ScrollView style={styles.droidSafeArea}>
      <View style={styles.about_us}>
      <Title       style={{
            height: 62,
            maxWidth: 300,
            marginTop: 30
          }}
          resizeMode="cover"
          source={ require('../assets/titles/about_us.jpg')}/>

        {/* <View style={styles.title_box}>
          <Text style={[styles.title, styles.orange]}>About</Text>
          <Text style={styles.title}> us</Text>
        </View> */}
        <Text style={[styles.text, styles.text_center]}>
          Jewish ethics, morals and values have shaped and refined individuals,
          nations and societies throughout the generations. However, many Jews
          today have never had the opportunity to explore Jewish ethics, morals
          and values in a mature and sophisticated way.
        </Text>

        <Image  style={{ marginBottom: 20, marginTop: 20, height: 115, width: '100%', maxWidth: 330 }} source={require("../assets/AboutUsPeople.png")}/>
        <View style={styles.items}>
          <View style={styles.item}>
            <View style={styles.item_ico}>
              <Icon2 name="about-mic" size={40} color="#000" />
            </View>
            <Text style={styles.item_title}>
              Why are we running Podcast Fellowship?
            </Text>
            <Text style={styles.text}>
              The Podcast Fellowship provides this opportunity for mature
              exploration. By running Podcast Fellowship we seek to give all
              Jews access to timeless Jewish wisdom.
            </Text>
          </View>
          <View style={styles.item}>
            <View style={styles.item_ico}>
              <Icon2 name="about-audio" size={32} color="#000" />
            </View>
            <Text style={styles.item_title}>
              What is the Podcast Fellowship?
            </Text>
            <Text style={styles.text}>
              The Podcast Fellowship is a paid educational framework aiming to
              empower its participants with Jewish literacy. The Fellowship
              allows advancement through the material at one’s own pace, based
              on one’s own unique interests and background.
            </Text>
          </View>
          <View style={styles.item}>
            <View style={styles.item_ico}>
              <Icon2 name="about-jewish" size={36} color="#000" />
            </View>
            <Text style={styles.item_title}>
              Who is Podcast Fellowship for?
            </Text>
            <Text style={styles.text}>
              The Podcast Fellowship is for Jewish college-aged students and
              young professionals with limited or no Jewish educational
              background who would like to enhance their life and ability to
              represent Judaism and Jewish values.
            </Text>
          </View>
          <View style={styles.item}>
            <View style={styles.item_ico}>
              <Icon2 name="about-jewish-book" size={40} color="#000" />
            </View>
            <Text style={styles.item_title}>Why become a Podcast Fellow?</Text>
            <Text style={styles.text}>
              Become a Podcast Fellow if you are interested in advancing your
              Jewish literacy and earning a generous stipend.
            </Text>
          </View>
        </View>
      </View>
    </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  droidSafeArea: {
    paddingTop: Platform.OS === "android" ? 35 : 0,
  },
  about_us: {
    flex: 1,
    alignItems: "center",
    width: "90%",
    alignSelf: "center",
    paddingVertical: 20,
    maxWidth: 600,
    lineHeight: 24,
  },
  title_box: {
    flexDirection: "row",
    marginBottom: 15,
    justifyContent: "center",
  },
  text_center: {
    textAlign: "center",
  },
  title: {
    fontSize: 26,
    lineHeight: 39,
    color: "#021c3c",
    fontWeight: "bold",
  },
  orange: {
    color: "#fcb912",
  },
  text: {
    lineHeight: 24,
    fontFamily: 'SofiaPro'
  },
  items: {
    paddingBottom: 40,
  },
  item: {
    marginTop: 35,
  },
  item_ico: {
    width: 75,
    height: 75,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff8e8",
    borderRadius: 10,
  },
  item_title: {
    fontSize: 20,
    lineHeight: 30,
    color: "#021c3c",
    marginTop: 25,
    marginBottom: 10,
    fontFamily: 'Poppins_700Bold'
  },
});
