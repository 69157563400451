import React, { useEffect } from "react";
import { View, StatusBar} from "react-native";
import LogRocket from '@logrocket/react-native';
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { apiSelector } from "@podcasts/store/api/selector";
import { useDispatch, useSelector } from "react-redux";
import { ActivityIndicator } from "react-native";
import { createNavigationContainerRef } from "@react-navigation/native";
import MainDrawerNavigator from "./MainDrawerNavigator";
import {
  getSavedPodcastIds,
  getSavedSpeakersIds,
  getSavedCoursesIds,
  getSavedTopicsIds,
} from "@podcasts/store/dashboard";
import { enableScreens } from "react-native-screens";
import { useIsAuthenticated } from "@podcasts/hooks";
import { Platform } from "react-native";
import GetStartedPage from "../pages/GetStartedPage";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from '../pages/ResetPassword';
import LoginPage from "../pages/LoginPage";
import TabsHeader from "../components/TabsHeader";
import { NativeApplicationProcess, WebApplicationProcess  } from "../pages/ApplicationProcess/ApplicationProcess";
import { MentorApplication, MentorWebApplication } from "../pages/MentorApplication/MentorApplication";
import BackButton from "../components/BackButton";
import axios from "axios";
import { logOut } from "@podcasts/store/user";
import { userSelector } from "@podcasts/store/user";

enableScreens();

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "white",
  },
};

const Stack = createNativeStackNavigator();

const config = {
  screens: {
  Login: "login",
  ForgotPassword: 'forgot-password',
  Application: "application",
  ResetPassword: 'reset-password',
  MentorApplication: 'mentor-apply',
  Dashboard: {
      screens: {
        AboutUsPageStack: {
          screens: {
            AboutUsPage: "aboutus",
          },
        },
        ContactUsPageStack: {
          screens: {
            ContactUsPage: "contact",
          },
        },
        TriviaPageStack: {
          screens: {
            TriviaPage: "trivia",
          },
        },
        DonatePageStack: {
          screens: {
            DonatePage: "donate",
          },
        },
        PrivacyPolicyPageStack: {
          screens: {
            PrivacyPolicy: "privacy-policy",
          },
        },
        HomePageStack: {
          screens: {
            HomePage: "",
          },
        },
        Podcasts: {
          screens: {
            PodcastPages: {
              screens: {
                PodcastStack: {
                  screens: {
                    PodcastPage: "podcasts/:slug",
                    YourNotes: "podcasts/:slug/notes",
                  },
                },
                RequestPayoutPage: "podcasts/:slug/payout",
                PodcastSpeaker: "podcasts/:slug/speaker",
                PodcastTopic: "podcasts/:slug/topic",
              },
            },
            SearchPages: {
              screens: {
                SearchPage: "podcasts/search",
              },
            },
            ProfilePages: {
              screens: {
                StartPage: "profile",
                EditPage: "profile/edit",
                PaymentsPage: "profile/payments",
                ReferralsPage: "profile/referrals",
                RecentNotesPage: "profile/notes",
                ReferFriendPage: "profile/refer",
              },
            },
            TabsPages: {
              screens: {
                HomeTab: {
                  screens: {
                    FeaturedPage: "podcasts/featured",
                  },
                },
                SpeakersTab: {
                  screens: {
                    SpeakersPage: "podcasts/speakers",
                    SpeakerInnerPage: "podcasts/speakers/:slug",
                  },
                },
                TopicsTab: {
                  screens: {
                    TopicsPage: "podcasts/topics",
                    TopicsInnerPage: "podcasts/topics/:slug",
                  },
                },
                SeriesTab: {
                  screens: {
                    SeriesPage: "podcasts/courses",
                    SeriesInnerPage: "podcasts/courses/:slug",
                  },
                },
                LibraryTab: {
                  screens: {
                    Library: "podcasts/library",
                    SavedPodcastsPage: "podcasts/library/podcasts",
                    SavedSpeakersPage: "podcasts/library/speakers",
                    SavedTopicsPage: "podcasts/library/topics",
                    SavedCoursesPage: "podcasts/library/courses",
                    RecentlyViewedPage: "podcasts/library/recent",
                    SavedSpeakerPage: "podcasts/library/speakers/:slug",
                    SavedTopicPage: "podcasts/library/topics/:slug",
                    SavedCoursePage: "podcasts/library/courses/:slug",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

const linking = {
  config,
};

const RootNavigator = () => {
  const { loading } = useSelector(apiSelector);
  const isAuthenticated = useIsAuthenticated();
  const navigationRef = createNavigationContainerRef();
  const routeNameRef = React.useRef();
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);


  useEffect(() => {
    axios.interceptors.response.use(
      function(success) {
         return success;
      },
      function(error) {
        if(error.request.status === 401 && error.config.url !== 'login') {
            dispatch(logOut());
            navigate.navigation.reset({
              index: 0,
              routes: [{ name: Platform.OS === 'web'? 'Dashboard' : 'GetStarted'}]
            })
        }
        return Promise.reject(error);
      }
    );
  },[]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSavedPodcastIds());
      dispatch(getSavedSpeakersIds());
      dispatch(getSavedCoursesIds());
      dispatch(getSavedTopicsIds());
      if (user && user.id)
        LogRocket.identify(user.id, {
          email: user.email,
          firstName: user.fname,
          lastName: user.lname
        });
    }
  }, [isAuthenticated]);

  const webHeaderOption = {
    header: () => <TabsHeader />,
  };

  let commonOptions = {
    headerShadowVisible: false,
    headerBackTitleVisible: false,
    title: "",
    headerLeft: (props) => <BackButton backLinkTo={"/podcasts/featured"} />,
    header: () => <><TabsHeader /></>,
    // ...(Platform.OS === "web" ? webHeaderOption : {}),
  };

  return (
    <>
      {loading && (
        <View
          style={{
            backgroundColor: "#ffffff9e",
            position: "absolute",
            zIndex: 11,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#fcb912" />
        </View>
      )}
          <StatusBar barStyle="dark-content" backgroundColor="white" />
      <NavigationContainer
        linking={linking}
        theme={MyTheme}
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        }}
        // onStateChange={async () => {
        //   const previousRouteName = routeNameRef.current;
        //   const currentRouteName = navigationRef.current.getCurrentRoute().name;
        //   const trackScreenView = () => {
        //     // Your implementation of analytics goes here!
        //   };
  
        //   if (previousRouteName !== currentRouteName) {
        //     // Replace the line below to add the tracker from a mobile analytics SDK
        //     await trackScreenView(currentRouteName);
        //   }
  
        //   // Save the current route name for later comparison
        //   routeNameRef.current = currentRouteName;
        // }}
      >
        <Stack.Navigator
          initialRouteName={
            Platform.OS !== "web"
              ? isAuthenticated
                ? "Dashboard"
                : "GetStarted"
              : "Dashboard"
          }
        >
   
              <Stack.Screen
                name="GetStarted"
                options={{ headerShown: false, ...commonOptions }}
                component={GetStartedPage}
              />
              <Stack.Screen
                name="Login"
                options={{headerShown: false, commonOptions}}
                component={LoginPage}
              />

              <Stack.Screen
                name="ResetPassword"
                options={{headerShown: false, commonOptions}}
                component={ResetPassword}
              />

              <Stack.Screen
                name="Application"
                options={{headerShown: false, ...commonOptions}}
                component={NativeApplicationProcess}
              />
              <Stack.Screen
                name="MentorApplication"
                options={{headerShown: false, ...commonOptions}}
                component={MentorApplication}
              />  
              <Stack.Screen
                name="ForgotPassword"
                options={{headerShown: false, commonOptions}}
                component={ForgotPassword}
              />
          
          <Stack.Screen
            name="Dashboard"
            options={{ headerShown: false }}
            component={MainDrawerNavigator}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
};

export default RootNavigator;
