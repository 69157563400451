import * as React from "react";
import { StyleSheet, Image, TouchableOpacity, View } from "react-native";
import Icon from "./icons/CustomIcon";
import { useLinkTo } from "@react-navigation/native";
import BurgerIcon from "./icons/BurgerIcon";
import { useNavigation } from "@react-navigation/native";
import { useCallback } from 'react';
import DonateButton from "./DonateButton";

const NewHeaderRight = ({ }) => {

  return (
    <View style={styles.data_user}>
      <DonateButton />
    </View>
  );
};

export default NewHeaderRight;

const styles = StyleSheet.create({
  user: {
    width: "100%",
    paddingVertical: 8,
  },
  data_user: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    // marginTop: 40
  },
  search_btn: {
    marginRight: 27,
  },
});
