import {
    StyleSheet,
    ScrollView,
    ImageBackground,
    Linking,
    View,
    Platform
  } from "react-native";
import { useCallback, useEffect } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Header } from "@react-navigation/elements";
import HeaderRight from "../components/HeaderRight";
import Iframe  from '../components/Iframe';
import { useFocusEffect, useNavigation } from "@react-navigation/native";

export default function DonatePage2() {
  const insets = useSafeAreaInsets();
  const src = "https://podcastfellowship.kindful.com/embeds/00d0f2b3-2fb6-4719-9960-2253640f37e7?p=https&embedded=true&full_form=true";
  const donateUrl = "https://m.podcastfellowship.org/donate";

  const navigation = useNavigation();
  useFocusEffect(useCallback(() => {
    if (Platform.OS === 'ios') {
      const res = Linking.openURL(donateUrl);
      if (res) {
        navigation.goBack();
      }
    } else {
      window.onmessage = function(event) {
        if (event.data === "kclosed") {
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            childKindfulWindow.close();
            $k('body').css({'position': 'relative', 'height':'auto', 'overflow': 'auto'})
          } else {
            var donateframe = document.getElementById("kindful-iframe");
            donateframe.parentNode.removeChild(donateframe);
            $k('body').css({ 'position': 'relative', 'height':'auto', 'overflow': 'auto', 'width': '100%' })
          }
        } else if (typeof event.data == 'string' && event.data.substr(0, 10) == "kredirect|") {
          var redirectUrl = event.data.replace("kredirect|","")
          window.location = redirectUrl;
        }
      };
      return () => {
        window.onmessage = null;
      }
    }
  }, []));


  return (
    <ScrollView  style={{ flex: 1 }}>
      <ImageBackground source={require("../assets/donate_image.png")} resizeMode="cover" style={[styles.page_header, { paddingTop: insets.top }]}>
          <Header
          headerStyle={{ height: 60, backgroundColor: "rgba(0, 0, 0, 0)" }}
          layout={{ height: 100, width: 400 }}
          headerRight={() => <View style={{ marginRight: 20}}><HeaderRight   search={false} /></View>}
        />
      </ImageBackground>
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, zIndex: 55, position: 'relative' }}>
        {Platform.OS !== 'ios' && <Iframe src={src} style={{ height: 600, border: 'none', marginTop: Platform.OS === 'web' ? -40 : 0}}/> }
      </View>
    </View>
    </ScrollView>
  );
}
  
  const styles = StyleSheet.create({
    page_header: {
      height: 275,  
      position: 'relative',
      zIndex: -1 
    },
    topic_bg: {
      width: "100%",
      height: "100%",
    }
  });
  
  