import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import StartPage from "../pages/Profile/StartPage";
import EditPage from "../pages/Profile/EditPage";
import PaymentsPage from "../pages/Profile/PaymentsPage";
import RecentNotesPage from "../pages/Profile/RecentNotesPage";
import ReferFriendPage from "../pages/Profile/ReferFriendPage";
import BackButton from "../components/BackButton";
import BurgerIcon from "../components/icons/BurgerIcon";
import { TouchableOpacity, View, Platform} from "react-native";
import ReferralsPage from "../pages/Profile/ReferralsPage";
import DonateButton from "../components/DonateButton";

const Stack = createNativeStackNavigator();

const commonOtions = (backLinkTo) => {
  return {   
    title: "",
    headerShadowVisible: false,
    headerBackTitleVisible: false,
    headerLeft: (props) => <View style={{  marginRight: Platform.OS === 'web' ? 0 : 20 ,alignItems: 'flex-start',marginHorizontal: Platform.OS === 'web' ? 20 : 0, marginTop: Platform.OS === 'ios' ? -5 : 0}}><BackButton {...props} backLinkTo={backLinkTo} /></View>,
  };
};
const ProfileNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="StartPage"
        component={StartPage}
        options={({ navigation }) => ({
          title: "",
          headerShadowVisible: false,
          headerBackTitleVisible: false,
          headerTitle: (props) => null,
          headerLeft: (props) => <View style={{  marginRight: Platform.OS === 'web' ? 0 : 20 ,alignItems: 'flex-start',marginHorizontal: Platform.OS === 'web' ? 20 : 0, marginTop: Platform.OS === 'ios' ? -5 : 0}}><BackButton {...props} backLinkTo={'/'} /></View>,
          headerRight: (props) => (<View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 27}}>
            <DonateButton />
            <TouchableOpacity
              style={{ marginRight: 10 }}
              onPress={() => {
                navigation.openDrawer();
              }}
            >
              <BurgerIcon />
            </TouchableOpacity>
            </View>
          ),
        })}
      />
      <Stack.Screen
        name="EditPage"
        component={EditPage}
        options={{...commonOtions("/profile"), title: 'Edit Profile', headerTitleStyle: { fontFamily: "Poppins_600SemiBold", fontSize: 16 }}}
      />
      <Stack.Screen
        name="PaymentsPage"
        component={PaymentsPage}
        options={commonOtions("/profile")}
      />

      <Stack.Screen
        name="ReferralsPage"
        component={ReferralsPage}
        options={commonOtions("/profile")}
      />

      <Stack.Screen
        name="RecentNotesPage"
        component={RecentNotesPage}
        options={commonOtions("/profile")}
      />

      <Stack.Screen
        name="ReferFriendPage"
        component={ReferFriendPage}
        options={commonOtions("/profile")}
      />
    </Stack.Navigator>
  );
};

export default ProfileNavigator;
