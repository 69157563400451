import { Header } from "@react-navigation/elements";
import { Image, Pressable, Platform, View } from "react-native";
import HeaderRight from "../components/HeaderRight";
import { useLinkTo, useNavigation } from "@react-navigation/native";
import DonateButton from "./DonateButton";
import NewHeaderRight from "./NewHeaderRight";
import SearchInput from "./SearchInput";

const TabsHeader = ({ backgroundColor = "white" }) => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const onSearch = (d) => {
    navigation.navigate("SearchPages", { screen: 'SearchPage', params: {search: d }});
  }
  return (
    <><Header
      headerShadowVisible={false}
      headerBackTitleVisible={false}
      headerStyle={{
        height: 60,
        backgroundColor,
      }}
      layout={{ height: 100, width: 400 }}
      headerRight={() => <View style={{ marginRight: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 27 }}>
        <NewHeaderRight />
        </View>}
      headerTitleAlign="center"
      headerTitle={() => (
        <Pressable
          onPress={() => {
              linkTo("/");
          }}
        >
          <Image
            source={require("../assets/logo.png")}
            style={{ width: 120, height: 30, resizeMode: "contain" }}
          />
        </Pressable>
      )}
      headerLeft={() => <View style={{ marginLeft: 20 }}><HeaderRight search={false} /></View>}
    />
    <View style={{marginLeft: 20, marginRight: 20, marginTop: 8, marginBottom: 28}}><SearchInput placeholder="Search a podcast" onKeyChange={onSearch} /></View>
    </>
  );
};
export default TabsHeader;
