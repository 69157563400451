import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  SafeAreaView,
  Image
} from "react-native";
import { useRef } from 'react';
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import Icon2 from "../components/icons/CustomIcon2";
import { Formik } from "formik";
import * as yup from "yup";
import { useContact } from '@podcasts/hooks';
import Title from '../components/Title';

const schema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().required("Phone is required"),
  message: yup.string().required("Please write your message"),
});

export default function ContactUsPage() {

  const mutation = useContact(null);
  const ref = useRef()

  return (
    <SafeAreaView style={{ flex: 1}}>
    <ScrollView style={styles.droidSafeArea}>

      <View style={styles.contact_us}>
      <Title       style={{
            height: 62,
            maxWidth: 300,
            marginTop: 30
          }}
          resizeMode="cover"
          source={ require('../assets/titles/contact_us.jpg')}/>

        {/* <View style={styles.title_box}>
          <Text style={[styles.title, styles.orange]}>Contact</Text>
          <Text style={styles.title}> us</Text>
        </View> */}
        <View style={styles.contact_form}>
          <Formik
            innerRef={ref}
            initialValues={{
              first_name: "",
              email: "",
              last_name: "",
              phone: "",
              message: "",
            }}
            validationSchema={schema}
            onSubmit={async (body) => {
              try {
                const { data: { message }} = await mutation.mutateAsync(body);
                toast.show(message, {
                  placement: "bottom",
                  type: "success",
                });
                ref.current.resetForm()
              } catch (e) {
                toast.show(e.response.data.message, {
                  placement: "bottom",
                  type: "danger",
                });
              }

            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <View style={{ width: "100%" }}>
                <View style={styles.form_control}>
                  <CustomInput
                    onChangeText={handleChange("first_name")}
                    onBlur={handleBlur("first_name")}
                    value={values.first_name}
                    error={
                      errors.first_name && touched.first_name
                        ? errors.first_name
                        : null
                    }
                    label={"First Name *"}
                  />
                </View>

                <View style={styles.form_control}>
                  <CustomInput
                    onChangeText={handleChange("last_name")}
                    onBlur={handleBlur("last_name")}
                    value={values.last_name}
                    error={
                      errors.last_name && touched.last_name
                        ? errors.last_name
                        : null
                    }
                    label={"Last Name *"}
                  />
                </View>

                <View style={styles.form_control}>
                  <CustomInput
                    autoCapitalize="none"
                    keyboardType={'email-address'}
                    onChangeText={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                    error={errors.email && touched.email ? errors.email : null}
                    label={"Email *"}
                  />
                </View>

                <View style={styles.form_control}>
                  <CustomInput
                    onChangeText={handleChange("phone")}
                    onBlur={handleBlur("phone")}
                    value={values.phone}
                    error={errors.phone && touched.phone ? errors.phone : null}
                    label={"Phone *"}
                    keyboardType="phone-pad"
                    placeholder={"( _ _ _ )  _ _ _   _ _ _ _"}

                  />
                </View>

                <View style={styles.form_control}>
                  <CustomInput
                    onChangeText={handleChange("message")}
                    onBlur={handleBlur("message")}
                    value={values.message}
                    label={"Message *"}
                    placeholder={"Write your message..."}
                    multiline={true}
                    numberOfLines={8}
                    minHeight={175}
                    error={
                      errors.message && touched.message ? errors.message : null
                    }
                  />
                </View>

                <View style={styles.contact_btn}>
                  <CustomButton
                    name={"Submit"}
                    color="blue"
                    onPress={() => {
                      handleSubmit()
                    }}
                    decorator
                  />
                </View>
              </View>
            )}
          </Formik>
          <Image style={{ position: 'absolute', right: -20, bottom: -20, height: 552, width: 350, zIndex: -5}} source={require("../assets/contact-us-element.png")}/>

        </View>
        <View style={styles.contact_info}>
          <View style={styles.info_icon}>
            <Icon2 name="contact-massage" size={23} />
          </View>
          <Text style={styles.info_text}>info@podcastfellowship.org</Text>
          <Text style={styles.info_text}>(650) 427-9680</Text>
        </View>
        <View style={styles.contact_info}>
          <View style={styles.info_icon}>
            <Icon2 name="contact-map" size={24} />
          </View>
          <Text style={styles.info_text}>The Podcast Fellowship</Text>
          <Text style={styles.info_text}>24786 Sussex St</Text>
          <Text style={styles.info_text}>Oak Park MI 48237</Text>
        </View>
      </View>
    </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  droidSafeArea: {
    flex: 1,
    paddingTop: Platform.OS === "android" ? 35 : 0,
  },
  contact_us: {
    flex: 1,
    alignItems: "center",
    width: "90%",
    alignSelf: "center",
    paddingVertical: 20,
    maxWidth: 600,
    lineHeight: 24,
  },
  title_box: {
    flexDirection: "row",
    marginBottom: 15,
    justifyContent: "center",
  },
  title: {
    fontSize: 26,
    lineHeight: 39,
    color: "#021c3c",
    fontWeight: "bold",
  },
  orange: {
    color: "#fcb912",
  },
  text: {
    lineHeight: 24,
  },
  text_center: {
    textAlign: "center",
  },
  hyperlink: {
    textDecorationLine: "underline",
  },
  form_control: {
    marginTop: 15,
    width: "100%",
  },
  contact_btn: {
    width: "100%",
    alignSelf: "center",
    marginTop: 35,
    position: "relative",
  },
  btn_decor: {
    width: "100%",
    height: 55,
    borderColor: "#000",
    borderWidth: 1,
    borderRadius: 50,
    position: "absolute",
    right: -4,
    top: 4,
    zIndex: -1,
  },
  contact_form: {
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    marginBottom: 50,
  },
  contact_info: {
    paddingBottom: 50,
    alignItems: "center",
  },
  info_icon: {
    width: 56,
    height: 56,
    borderRadius: 28,
    borderColor: "#000",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  info_text: {
    fontSize: 18,
    lineHeight: 28,
    fontFamily: 'SofiaPro'
  },
});
