import { Pressable, StyleSheet, View, Text } from "react-native";
import { useLinkTo } from "@react-navigation/native";

export default function DonateButton({ text }) {

  const linkTo = useLinkTo();
  const onPress = () => {
    linkTo('/donate');
  };

  return (
    <Pressable onPress={onPress}>
        <View style={styles.btn}>
            <Text style={styles.text}>Donate</Text>
        </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  btn: {
    backgroundColor: "#FCB912",
    padding: 12,
    borderRadius: 1000,
    marginLeft: 10,
    width: 68
  },
  text: {
    color: "#042B59",
    fontSize: 12,
    fontWeight: 500,
    textAlign: "center",
  },
});